import { Fragment, useState } from "react";
import { observer } from "mobx-react";
import VolunteerEmailsDialog, { VolunteerEmailsDialogProps } from "../Volunteers/VolunteerEmailsDialog";
import REMButton from "../../../../../shared/modules/rem-conversion/components/Button";
import { Email } from "mdi-material-ui";
import { getInitialDialogStates } from "../../../../../logic/DialogStateDictionary";

enum DialogTypes {
    EmailsDialog = 'emailsDialog',
}

interface EmailsButtonProps extends Omit<VolunteerEmailsDialogProps, 'state'> {

}

const EmailsButton = observer((props: EmailsButtonProps) => {

    const [dialogStates] = useState(getInitialDialogStates(Object.values(DialogTypes)));

    /********* Render *********/

    return (
        <Fragment>
            <REMButton
                startIcon={<Email />}
                variant="outlined"
                size="large"
                onClick={() => dialogStates.emailsDialog.setOpen(true)}
            >
                Emails
            </REMButton>
            <VolunteerEmailsDialog
                state={dialogStates.emailsDialog}
                emails={props.emails}
                numSelected={props.numSelected}
            />
        </Fragment>
    );
});

export default EmailsButton;
